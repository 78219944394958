
import ValidationMixin from "@/mixins/validation";
import {
  DepartmentTypes,
  UserDepartment,
} from "@/store/modules/department/department.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

const departmentX = namespace("Department");

@Component({})
export default class EditDepartment extends Mixins(ValidationMixin) {
  @departmentX.State(DepartmentTypes.DELETE_DEPARTMENT_STATE)
  public deleteDepartment!: DefaultState;

  @departmentX.State(DepartmentTypes.DEPARTMENT_DATA)
  public userDepartment!: UserDepartment;

  @departmentX.Mutation(DepartmentTypes.SET_DELETE_DEPARTMENT_DIALOG)
  public setDeleteDepartment!: (deleteDepartment: boolean) => void;

  @departmentX.Action(DepartmentTypes.DELETE_DEPARTMENT)
  public deleteUserDepartment!: () => Promise<void>;

  public updateDepartmentStep = 1;

  public isValid = false;

  @Ref("form") public formRef!: Validator;

  closeDeleteDepartmentDialog(): void {
    this.setDeleteDepartment(false);
  }
}
